import React, { useState } from 'react';
import Sidebar from './selected-sidebar';
import Button from './button';
import { svgIcon, isTouchDevice, getImageSrc } from '../../../Functions';
import { generateCustomPdfAnalatics, generateCustomPdfLink } from '../../../Action';
import { CustomPdfGenerateAnalatics } from '../../../Functions/analytic';

const PDF = (props) => {
    const [selected, setSelected] = useState([]);
    const [openSideBar, setOpenSideBar] = useState(false);

    const selectThisImage = (pageNo, isSelected) => {
        if (isSelected) {
            const omitPage = selected.filter((p) => p !== pageNo);
            setSelected(omitPage);
        } else {
            setSelected([...selected, pageNo]);
        }
    };

    const showPages = () => {
        const { pdf, pdf: { totalPages } } = props;

        return Array.from({ length: totalPages }, (_, i) => i + 1).map((i) => {
            const alreadyAdded = selected.includes(i);
            let divClass = alreadyAdded ? "page-thumb page-thumb-width added" : " page-thumb-width page-thumb";
            if (isTouchDevice()) divClass += " has-touch";

            return (
                <div className={divClass} key={i} onClick={() => (isTouchDevice() ? selectThisImage(i, alreadyAdded) : '')}>
                    <img src={getImageSrc(i, 2, pdf)} alt={`Page ${i}`} />
                    <div className="thumb-overlay cursorPointer" onClick={() => selectThisImage(i, alreadyAdded)}>
                        <div className="overlay-bg"></div>
                        <span className="add-page">
                            {svgIcon('ico-tap')}
                        </span>
                    </div>
                </div>
            );
        });
    };

    const createCustomPdf = async () => {
        generateCustomPdfAnalatics({
            companySlug: window.__E_TT_Client.companySlug,
            pdfID: props.pdf.pdfID,
        });
        const res = await generateCustomPdfLink({
            page_no: selected,
            pdf_id: props.pdf.pdfID,
        });
        if (res && res.customPath) {
            props.toggle();
            props.shareLink({
                isActive: true,
                data: {
                    link: res.customPath,
                },
                type: 'custom-pdf',
            });
            CustomPdfGenerateAnalatics({
                pdfID: props.pdf.pdfID,
                companySlug: window.__E_TT_Client.companySlug,
                selectedpages: selected,
                customPath: res.customPath,
                customSlug: res.customSlug
            })



        }
    };

    if (!props.isActive || !props.pdf) return null;

    return (
        <div className="epaper-custompdf">
            <div className="pdf-wrapper">
                <div className="page-selector">
                    {showPages()}

                    {!openSideBar && (
                        <div
                            className={`${selected.length > 0 ? 'genrate-btn' : 'genrate-btn disabled'
                                }`}
                            onClick={() => setOpenSideBar(true)}
                        >
                            <span>Show Selected ({selected.length})</span>
                        </div>
                    )}
                </div>

                <Sidebar
                    pdf={props.pdf}
                    sel={selected}
                    remove={selectThisImage}
                    isOpen={openSideBar}
                    close={() => setOpenSideBar(false)}
                    create={createCustomPdf}
                    count={selected.length}
                />
            </div>
        </div>
    );
};

export default PDF;
