import React, { useState, useEffect } from "react";
import { svgIcon } from "../../Functions";
import { HOST } from "../../config";
import Service from "../../Action/service";
import { generatePDFFromImages } from "../../Functions/images";
import { getDownloadTimeApi } from "../../Action";
import axios from "axios";
import { ContactUsAnalatics, PdfDownloadAnalatics, PdfShareAnalatics } from "../../Functions/analytic";

const BottomNavigation = (props) => {
  const [active, setActive] = useState(false);
  const [showIcons, setShowIcons] = useState({});
  const [DownloadPDFloader, setDownloadPDFloader] = useState(false);

  const fetchEnableOrNot = async () => {
    try {
      let com_slug = window.__E_TT_Client.companySlug;
      const result = await Service.get(`public/getenableornot/${com_slug}`);
      if (result?.data?.success) {
        setShowIcons(result?.data?.data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchEnableOrNot();
  }, []);

  const enablePopup = (type, data = {}, socialMediaLinks) => {
    let popupConfig = {
      isActive: true,
      data,
      type,
      socialMediaLinks,
    };

    props.togglePopup(popupConfig);
  };

  const menuSlider = () => {
    setActive(!active);
  };

  const downloadPDF = async (url, pdfSlug, pages) => {
    if (pages) {
      setDownloadPDFloader(true);
      await generatePDFFromImages(props.pdf.pdfID, pages).then(() => {
        setDownloadPDFloader(false);
      });
    } else {
      const { showForm } = props.pdf;
      const data = {
        url,
        pdfSlug,
        pdfID: props.pdf.pdfID,
        clientID: props.pdf.clientID,
        isHeader: false,
      };

      const popupConfig = {
        isActive: true,
        data,
        type: "downloadPDF",
      };

      if (showForm) {
        enablePopup("downloadPDF", popupConfig);
      } else {
        setDownloadPDFloader(true);
        await Service.get(url).then(async (res) => {
          const url = res.data.data;
          const Filename = res.headers['content-language'];
          let downloadStartTime;
          axios
            .get(url, {
              responseType: "blob",
              onDownloadProgress: (progressEvent) => {
                if (!downloadStartTime) {
                  downloadStartTime = new Date();
                }
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded / total) * 100);
              },
            })
            .then((response) => {
              const downloadEndTime = new Date();
              const downloadTimeInMilliseconds =
                downloadEndTime - downloadStartTime;

              const downloadTimeInSeconds = Math.floor(
                downloadTimeInMilliseconds / 1000
              );
              const downloadTimeMinutes = Math.floor(
                downloadTimeInSeconds / 60
              );
              const downloadTimeSeconds = downloadTimeInSeconds % 60;
              getDownloadTimeApi({
                pdfID: data.pdfID,
                clientID: data.clientID,
                downTime: downloadTimeInMilliseconds,
              });
              setDownloadPDFloader(false);

              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const pdfUrl = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = pdfUrl;
              a.download = Filename;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(pdfUrl);
            })
            .catch((error) => {
              console.error("Error downloading PDF:", error);
              setDownloadPDFloader(false);
            });
        });
        PdfDownloadAnalatics({
          companySlug: window.__E_TT_Client.companySlug,
          pdfID: props.pdf.pdfID,
          isHeader: false,
          isForm: false
        })
      }
    }
  };

  const getDownloadLink = () => {
    const { pdfSlug, pdfID, parent, pages } = props.pdf || {};
    let link;

    if (parent) link = `${HOST}/viewsofpdf?id=${pdfID}&timestamp=${pdfSlug}`;
    else link = `${HOST}/pdfdownload/${pdfID}`;

    if (link) {
      return (
        <button
          id="idico-download"
          onClick={() => downloadPDF(link, pdfSlug, pages)}
          children={svgIcon("ico-download")}
        />
      );
    }
    return null;
  };

  return (
    <div
      className={`bottom-navigation ${active && "open"} ${props.howItWorksVideo && "videoAdded"
        } ${props.atHome && "home"}`}
      style={{
        display: props.embededlink ? "" : "none",
      }}
    >
      {
        localStorage.getItem("isoffline") === 'false' && (
          <ul className={`plus ${active && "cross"}`} onClick={menuSlider}>
            {props.hideDownload?.hideDownloadHome !== true && (
              <>
                {props.pdf?.lowrise && (
                  <li title="Download" id="idico-download">
                    {DownloadPDFloader ? (
                      <span style={{ paddingLeft: "7px" }}>
                        <div className="loader-container">
                          <div className={`loaderingpdf`} />
                        </div>
                      </span>
                    ) : (
                      <span>
                        <span
                          className="nav-btn"
                          id="idico-download"
                          children={getDownloadLink()}
                        />
                        <i>Download</i>
                      </span>
                    )}
                  </li>
                )}
              </>
            )}

            {!props.hideCustomPdf &&
              !props.Generatedpdf &&
              !props?.location?.pathname?.includes("custom") && (
                <li
                  id="idico-pdfGenerate"
                  onClick={props.toggleGenerate}
                  title="Create your own catalog"
                >
                  <span id="idico-pdfGenerate">
                    {svgIcon("ico-pdfGenerate")}
                    <i>Generate PDF</i>
                  </span>
                </li>
              )}
            {showIcons?.showShare && (
              <li
                id="idico-share"
                title="Share"
                onClick={async () => {
                  enablePopup("share");


                  await Service.post(`public/shareclick`, {
                    companySlug: window.__E_TT_Client.companySlug,
                    pdfID: props.pdfID,
                    isHome: props.isHome,
                  });

                  PdfShareAnalatics({
                    companySlug: window.__E_TT_Client.companySlug,
                    pdfID: props.pdfID,
                  })
                }}
              >
                <span id="idico-share">
                  {svgIcon("ico-share")}
                  <i>Share</i>
                </span>
              </li>
            )}
            {showIcons?.showContact && (
              <li
                id="idico-contact"
                title="Contact"
                onClick={async () => {
                  enablePopup(
                    "contact",
                    props.pdf.contactInfo,
                    props.socialMediaLinks
                  );
                  ContactUsAnalatics({
                    companySlug: window.__E_TT_Client.companySlug,
                  })
                  await Service.post(`public/clickoncontactus`, {
                    companySlug: window.__E_TT_Client.companySlug,
                  });

                }}
              >
                <span id="idico-contact">
                  {svgIcon("ico-contact")}
                  <i>Contact</i>
                </span>
              </li>
            )}
          </ul>
        )
      }

    </div>
  );
};

export default BottomNavigation;
